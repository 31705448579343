<template>
  <div class="form form-publication_url">

    <v-form
      ref="form"
      class="pa-6"
      v-model="valid"
    >
      <Loading :value="loading" />
      <v-dialog
        v-model="dialogMacro"
        width="350px"
        overlay-color="transparent"
      >
        <v-card>
          <div class="secondary">
            <v-toolbar
              color="transparent"
              class="dialog-header"
              flat
            >
              <v-avatar class="mr-4" size="40">
                <v-icon size="24">mdi-code-braces</v-icon>
              </v-avatar>
              <v-toolbar-title>
                <span class="text-h5 text-capitalize">{{ $tc('MACRO', 0) }}</span>
              </v-toolbar-title>
              <v-spacer/>
              <v-btn
                icon
                @click="dialogMacro = false"
                class="ml-3"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider />
          </div>

          <div class="dialog-content">
            <v-form
              ref="formMacro"
              v-model="validMacro"
              class="pr-4 pl-4"
            >
              <br >
              <p>Type a macro (without brace nor space).</p>
              <v-row class="mt-0 mb-0">
                <v-col cols="12" class="pt-0 pb-0">
                  <v-text-field
                    v-model="macro"
                    :label="$t('INPUT.MACRO')"
                    :placeholder="$t('INPUT.MACRO')"
                    :rules="[$rules.required]"
                    required
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
            </v-form>
            <div class="text-center pa-4 pt-0">
              <v-btn
                @click="confirmMacro"
                large
                depressed
                rounded
                color=" success custom"
                :disabled="!validMacro"
              >
                {{ $t(macroToReplace ? 'COMMON.APPLY' : 'COMMON.ADD') }}
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogMacroItem"
        width="350px"
        overlay-color="transparent"
      >
        <v-card>
          <div class="secondary">
            <v-toolbar
              color="transparent"
              class="dialog-header"
              flat
            >
              <v-avatar class="mr-4" size="40">
                <v-icon size="24">mdi-code-braces</v-icon>
              </v-avatar>
              <v-toolbar-title>
                <span class="text-h5 text-capitalize">{{ $tc('MACRO', 0) }}: {{ macro }}</span>
              </v-toolbar-title>
              <v-spacer/>
              <v-btn
                icon
                @click="dialogMacroItem = false"
                class="ml-3"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider />
          </div>

          <div class="dialog-content">
            <v-form
              ref="formMacroItem"
              v-model="validMacroItem"
              class="pr-4 pl-4"
              v-if="macroItem"
            >
              <br >
              <p>Configure a case for <strong>{{ '{'+macro+'}' }}</strong></p>
              <v-row class="mt-0 mb-0">
                <v-col cols="1" class="pt-2 pb-0 pr-0 text-nowrap text-right grey--text">
                  <strong>if</strong>
                </v-col>
                <v-col cols="4" class="pt-0 pb-0 pr-0">
                  <v-select
                    v-model="macroItem.type"
                    :label="$t('INPUT.MACRO_TYPE')"
                    :placeholder="$t('INPUT.MACRO_TYPE')"
                    :items="macroTypesItems"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="7" class="pt-0 pb-0">
                  <v-text-field
                    v-model="macroItem.on"
                    :label="$t('INPUT.MACRO_ON')"
                    :placeholder="$t('INPUT.MACRO_ON')"
                    :rules="[$rules.required]"
                    required
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
              <v-row class="mt-0 mb-0">
                <v-col cols="1" class="text-center pt-2"><v-icon color="grey" size="20">mdi-equal</v-icon></v-col>
                <v-col cols="11" class="pt-0 pb-0">
                  <v-text-field
                    v-model="macroItem.trigger"
                    :label="$t('INPUT.MACRO_TRIGGER')"
                    :placeholder="$t('INPUT.MACRO_TRIGGER')"
                    :rules="[$rules.required]"
                    required
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
              <p>Will be replaced by</p>
              <v-row class="mt-0 mb-0">
                <v-col cols="12" class="pt-0 pb-0">
                  <v-text-field
                    v-model="macroItem.value"
                    :label="$t('INPUT.MACRO_VALUE')"
                    :placeholder="$t('INPUT.MACRO_VALUE')"
                    :rules="[$rules.required]"
                    required
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
              <!-- <v-row class="mt-0 mb-0">
                <v-col cols="1" class="pt-2 pb-0 pr-0 text-nowrap text-right">
                  <strong>or</strong>
                </v-col>
                <v-col cols="11" class="pt-0 pb-0">
                  <v-text-field
                    v-model="macroItem.default_value"
                    :label="$t('INPUT.MACRO_DEFAULT_VALUE')"
                    :placeholder="$t('INPUT.MACRO_DEFAULT_VALUE')"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row> -->
            </v-form>
            <div class="text-center pa-4 pt-0">
              <v-btn
                @click="confirmMacroItem"
                large
                depressed
                rounded
                color=" success custom"
                :disabled="!validMacroItem"
              >
                {{ $t(macroItemToReplace ? 'COMMON.APPLY' : 'COMMON.ADD') }}
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>

      <div class="pa-0">
        <v-row v-if="items.length">
          <v-col cols="8">
            <v-text-field
              dense
              v-model="id"
              disabled
              :label="$t('INPUT.ID')"
              :placeholder="$t('INPUT.ID')"
              outlined
            />
          </v-col>
          <v-col cols="4">
            <v-select
              dense
              v-model="status"
              :items="[{text: $t('INPUT.STATUS_0'), value: 0}, {text: $t('INPUT.STATUS_1'), value: 1}]"
              :label="$t('INPUT.STATUS')"
              :placeholder="$t('INPUT.STATUS')"
              item-value="value"
              item-text="text"
              outlined
            />
          </v-col>
        </v-row>
      </div>

      <p class="title-separator">Template</p>

      <div class="pa-0 pb-4" v-if="macros && Object.keys(macros).length > 0">
        <template v-for="macro in Object.keys(macros)">
          <v-chip
            :key="'ch'+macro"
            v-if="!template.includes('{'+macro+'}')"
            small
            @click="template += '{'+macro+'}'"
          >
            {{ '{'+macro+'}' }}
          </v-chip>
          <v-chip
            :key="'ch'+macro"
            v-else
            small
            class="muted"
          >
            {{ '{'+macro+'}' }}
          </v-chip>
        </template>
      </div>

      <div class="pa-0">
        <v-text-field
          dense
          v-model="template"
          :rules="[$rules.required]"
          :label="$t('INPUT.URL_TEMPLATE')"
          :placeholder="$t('INPUT.URL_TEMPLATE')"
          outlined
          required
        />
      </div>

      <p class="title-separator">Macros</p>
      <div class="pa-0">
        <v-btn
          color="secondary black--text custom"
          @click="editMacro"
          small
          rounded
          depressed
        >
          <v-icon class="mr-2">mdi-plus</v-icon>
          {{ $t('COMMON.ADD') }}
        </v-btn>
      </div>

      <div class="pa-0" v-if="macros && Object.keys(macros).length > 0">
        <template v-for="macro in Object.keys(macros)">

          <v-card
            outlined
            flat
            :key="'li'+macro"
            class="mt-4 muted"
            v-if="macro === 'url'"
          >
            <v-toolbar
              dense
              flat
              color="secondary"
            >
              <v-toolbar-title>{{ '{'+macro+'}' }}</v-toolbar-title>
              <v-spacer />
              <v-chip outlined>Default macro</v-chip>
            </v-toolbar>
          </v-card>

          <v-card
            outlined
            flat
            :key="'li'+macro"
            class="mt-4 "
            v-else
          >
            <div class="secondary">
              <v-toolbar
                dense
                flat
                color="transparent"
              >
                <v-toolbar-title>{{ '{'+macro+'}' }}</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-divider />
            </div>
            <v-simple-table v-if="macros[macro]">
              <template v-slot:default>
                <thead class="secondary">
                  <tr>
                    <th class="text-left">
                      On
                    </th>
                    <th class="text-left">
                      Trigger
                    </th>
                    <th class="text-left">
                      Value
                    </th>
                    <th class="text-right">
                      <div>
                        <v-btn icon @click="removeMacro(macro)"><v-icon size="20">mdi-delete</v-icon></v-btn>
                        <v-btn icon @click="editMacro(macro)"><v-icon size="20">mdi-pencil</v-icon></v-btn>
                        <v-btn icon @click="editMacroItem(macro)"><v-icon size="20">mdi-plus-thick</v-icon></v-btn>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item,i) in macros[macro]"
                    :key="item.name"
                  >
                    <td>{{item.on}}</td>
                    <td>{{item.trigger}}</td>
                    <td>{{item.value}}</td>
                    <td class="text-right">
                      <div>
                        <v-btn icon @click="removeMacroItem(macro, i)"><v-icon size="20">mdi-delete</v-icon></v-btn>
                        <v-btn icon @click="editMacroItem(macro, i, item)"><v-icon size="20">mdi-pencil</v-icon></v-btn>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <!--
            <v-list dense class="pa-0">
              <template v-if="macros[macro]" v-for="(item,i) in macros[macro]">
                <v-divider />
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      if <strong>{{item.on}}</strong> = <strong>{{item.trigger}}</strong>
                    </v-list-item-subtitle>
                    <v-list-item-title><strong>{{item.value}}</strong></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div>
                      <v-btn icon @click="removeMacroItem(macro, i)"><v-icon size="20">mdi-delete</v-icon></v-btn>
                      <v-btn icon @click="editMacroItem(macro, i, item)"><v-icon size="20">mdi-pencil</v-icon></v-btn>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
            -->
          </v-card>

        </template>
      </div>
    </v-form>
    <v-divider />

    <v-toolbar
      class="white"
      flat
      height="70px"
    >
      <v-btn
        @click="resetItem"
        fab
        x-small
        depressed
        color="warning"
      >
        <v-icon>mdi-backup-restore</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn
        color="success custom"
        rounded
        depressed
        :disabled="!valid && !loading"
        large
        @click="validate"
      >
        <v-icon class="mr-2">mdi-check-bold</v-icon>
        <strong>{{ $t(items.length ? 'COMMON.APPLY' : 'COMMON.CREATE') }}</strong>
      </v-btn>
    </v-toolbar>

  </div>

</template>

<script>
import Loading from '@/components/Loading'

const type = ['regex', 'data']

export default {
  name: 'FormPublicationUrl',
  components: {
    Loading
  },
  data: (_t) => ({
    macroTypesItems: [
      { text:_t.$i18n.t('INPUT.MACRO_TYPE_DATA'), value: 'data' },
      { text:_t.$i18n.t('INPUT.MACRO_TYPE_REGEX'), value: 'regex' },
    ],
    loading: false,
    valid: false,
    items: [],

    // form macro
    validMacro: false,
    dialogMacro: false,
    macro: undefined,
    macroToReplace: undefined,

    // form macro item
    validMacroItem: false,
    dialogMacroItem: false,
    macroItem: undefined,
    macroItemToReplace: undefined,

    // form
    id: undefined,
    status: undefined,
    template: '{url}',
    macros: { url:true, },
  }),
  props: {
    props: {
      type: [Object, Boolean],
      default: false,
    },
    // items: {
    //   type: [Array, Boolean],
    //   default: false,
    // },
  },

  watch: {
    loading (n) {
      this.$emit('loading', n)
    },
    // items (n) {
    //   this.assignItems(n)
    // },
  },
  mounted () {
    this.loadPublicationUrl()
  },
  methods: {

    editMacro (key) {
      key = typeof key === 'string' ? key : undefined
      if (key) this.macroToReplace = key
      this.macro = key || null
      this.dialogMacro = true
    },

    removeMacro (key) {
      if (this.macros[this.macro]) {
        delete this.macros[this.macro]
        this.template = this.template.replace(new RegExp(this.macro, 'gmi'), '')
      }
    },

    confirmMacro () {
      if (this.$refs.formMacro.validate()) {
        let content = this.macroToReplace ? this.macros[this.macroToReplace] : []
        let key = this.macro
        this.macros[this.macro] = content
        this.macro = null
        this.macroToReplace = null
        this.dialogMacro = false
      }
    },

    editMacroItem (macro, key, data) {
      key = typeof key === 'string' ? key : undefined
      this.macro = macro
      this.macroKey = key || 0
      this.macroItem = data || {
        type: undefined,
        on: '',
        trigger: '',
        value: '',
        default_value: '',
      }
      this.dialogMacroItem = true
    },

    removeMacroItem (macro, key) {
      if (this.macros[this.macro] && this.macros[this.macro][key]) {
        this.macros.splice(key)
      }
    },

    confirmMacroItem () {
      if (this.$refs.formMacroItem.validate()) {
        // if (!(this.macros[this.macro] instanceof Array)) this.macros[this.macro] = []
        if (this.macroKey !== null) {
          this.macros[this.macro][this.macroKey] = Object.assign({}, this.macroItem)
        } else {
          this.macros[this.macro].push(Object.assign({}, this.macroItem))
        }
        this.macro = undefined
        this.macroItem = undefined
        this.macroKey = undefined
        this.dialogMacroItem = false
      }
    },

    loadPublicationUrl () {
      this.loading = true
      return this.$services.api_programmatic.publication_url.search()
        .then(response => {
          if (response.data) {
            this.items = response.data
            this.assignItems(response.data)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    assignItems (items) {
      if (items.length === 1) {
        const data = items[0]
        if (typeof data.id !== 'undefined') this.id = data.id
        if (typeof data.status !== 'undefined') this.status = data.status
        if (typeof data.template !== 'undefined') this.template = data.template
        if (typeof data.macros !== 'undefined') this.macros = this.$clone(data.macros)
      }
    },

    resetItem () {
      if (this.items.length === 1) {
        this.assignItems(this.items)
      } else {
        this.id = undefined
        this.status = undefined
        this.template = '{url}'
        this.macros = {url:true}
      }
    },

    validate () {
      if (this.$refs.form.validate()) {
        if (this.items.length > 0) return this.updateItem()
        return this.createItem()
      }
    },

    createItem () {
      // prepare values
      const values = {
        status: this.status,
        template: this.template,
        macros: this.macros,
      }
      const query = {
        values
      }
      this.loading = true
      this.$services.api_programmatic.publication_url.create(query)
        .then(response => {
          this.$emit('update')
          this.$emit('select', [response.data])
          this.$emit('create', response.data[1])
        })
        .catch(response => {
          this.$store.dispatch('messenger/add', {type: 'error', code: response.code, message: response.message, time: 5000 })
        })
        .finally(() => {
          this.loading = false
        })
    },

    updateItem () {
      // prepare values
      const oldValue = this.items[0] || {}
      const values = {}
      if (typeof this.template !== 'undefined' && this.template !== oldValue.template) values.template = this.template
      if (typeof this.status !== 'undefined' && this.status !== oldValue.status) values.status = this.status
      if (typeof this.macros !== 'undefined' && JSON.stringify(this.macros) !== JSON.stringify(oldValue.macros)) values.macros = this.macros

      const query = {
        where: { id: this.items.map(v => v.id) },
        values
      }
      this.loading = true
      this.$services.api_programmatic.publication_url.update(query)
        .then(response => {
          this.loading = false
          this.$emit('update')
        })
        .catch(response => {
          this.$store.dispatch('messenger/add', {type: 'error', code: response.code, message: response.message, time: 5000 })
        })
        .finally(() => {
          this.loading = false
        })
    },

    removeItem () {
      const ids = this.items.map(v => v.id)
      this.$services.api_programmatic.publication_url.remove({ where: { id: ids }})
        .then(response => {
          this.$emit('update')
          this.$emit('select', [])
          this.$emit('close')
        })
    },

    refreshItem () {
      const ids = this.items.map(v => v.id)
      this.$services.api_programmatic.publication_url.refresh({ where: { id: ids }})
        .then(response => {
          this.$emit('update')
        })
    },
  }
}
</script>
